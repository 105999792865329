import { useEffect, useState } from "react";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { Products } from "./Products";
import { Solutions } from "./Solutions";
import { LANDING_PAGE_DESCRIPTION } from "../utils/constant";
import { Comments } from "./Comments";
import { ProgressChart } from "./ProgressChart";
import { FAQs } from "./FAQs";
import Typewriter from "typewriter-effect";
import { ClientLogos } from "./ClientLogos";
import { UpArrowIcon } from "../assets";

export const BasePage = () => {
  const [scroll, setScroll] = useState(false);
  const [startTrialShow, setStartTrialShow] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 250);
      setStartTrialShow(window.scrollY > 800);
    });
  }, []);

  return (
    <div className="wrapper">
      <Header scroll={scroll} />
      <section className="banner" id="banner">
        <div className="container">
          <div
            className="banner-content"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <div>
              <div className="anim">
                <h1>Employee Engagement</h1>
                <Typewriter
                  options={{
                    strings: ["Gamified", "Reimagined", "Modernized"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
            </div>
            <div>{LANDING_PAGE_DESCRIPTION}</div>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  window.location.href =
                  "mailto:team@fettle.plus?subject=Fettle%20|%20Demo%20Request%20|%20Website&body=Hi,%20I'd%20like%20to%20know%20more%20about%20employee%20engagement%20with%20Fettle!%0D%0AMy%20work%20email%20is%20________________";
                }}
              >
                Start Free Trial
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className={"sticky-cta " + (startTrialShow ? "visible" : "")}>
        <button
          type="button"
          className="btn btn-primary btn-left"
          onClick={() => {
            window.location.href =
              "mailto:team@fettle.plus?subject=Fettle%20|%20Demo%20Request%20|%20Website&body=Hi,%20I'd%20like%20to%20know%20more%20about%20employee%20engagement%20with%20Fettle!%0D%0AMy%20work%20email%20is%20________________";
          }}
        >
          Start Free Trial
        </button>
        <button
          type="button"
          className="btn btn-primary btn-right"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <UpArrowIcon />
        </button>
      </section>
      <Products />
      <ProgressChart />
      <Solutions />
      <Comments />
      <ClientLogos />
      <FAQs />
      <Footer />
    </div>
  );
};
