import React from "react";
import { v4 as uuidv4 } from "uuid";

export const SolutionCard = ({ data, index }) => {
  return (
    <div className="case1">
      <div
        className={
          "row align-items-center" +
          (index % 2 === 0 ? "" : " d-flex flex-row-reverse ")
        }
      >
        <div className="col-md-6">
          <div className="text">
            <label>{data.label}</label>
            <br />
            <label>
              <h3>{data.title}</h3>
            </label>
            <p>{data.description}</p>
            <ul>
              {React.Children.toArray(
                data.points.map((point) => <li key={uuidv4()}>{point}</li>)
              )}
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div data-aos="fade-up" data-aos-duration="1000">
            <img src={data.imgSrc} alt="solution" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};
