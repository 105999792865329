import { v4 as uuidv4 } from "uuid";
import { SOLUTIONS } from "../../utils/constant";
import { SolutionCard } from "../../components/Cards/SolutionCard";

export const Solutions = () => {
  return (
    <section className="cases text-center">
      <div className="container">
          <span>Use Cases</span>
          <h2>{SOLUTIONS.label}</h2>
          {SOLUTIONS.description}

          {SOLUTIONS.data.map((item, index) => (
              <SolutionCard key={uuidv4()} data={item} index={index} />
          ))}

          <div className="sep" />
      </div>
    </section>
  );
};
