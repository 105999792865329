import React from "react";
import Helmet from "react-helmet";
import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
  return (
    <>
    <Helmet>
      <title>Fettle | Privacy Policy</title>
    </Helmet>
      <div className="policy-container">
        <div className="policy-title">
          <p>Privacy Policy</p>
        </div>
        <p className="policy-body">
          In this pervasive age of social media, we understand the importance of
          a user’s privacy and data and to that end we put a very high value to
          it. Fettle is committed to handling your personal data with utmost
          diligence and in a way which instils trust. We believe it is also
          important for you to know how we handle all your data, so do give this
          privacy policy a read. <br />
          This privacy policy explains the types of personal data we collect and
          how we use and secure that information.
        </p>
        <p className="policy-header">About us</p>
        <p className="policy-body">
          Fettle mobile application is an online platform for managing, and
          sharing all of your fitness activities. It is provided to you by FETL
          Tech (“us” or “we” or “our” or “Fettle”), in connection with our
          partners, service providers, sponsors, or other affiliates. So that we
          may safely and responsibly manage this platform for all of our users,
          your use of this application is subject to certain terms and
          conditions.
        </p>
        <p className="policy-header">Scope</p>
        <p className="policy-body">
          This privacy policy applies to all personal data that is collected and
          used by us when you create a Fettle account when you visit or use the
          application, participate in events of Fettle or interact with us
          through social media or otherwise. For purposes of this privacy
          policy, ‘personal data’ means any information through which we can
          identify you.
        </p>
        <p className="policy-header">Information we collect</p>
        <ul>
          <li>
            Your contact details. Your name, postal address and other contact
            details, such as your telephone number and e-mail address.
          </li>
          <li>
            Your account data and electronic identification data (including data
            added thereto). Your Fettle ID account, other electronic
            identification data (such as IDFA, GAID, IP address, MAC address)
            and the data you add to your Fettle ID account such as your
            password, your date of birth, your gender, the time zone you live
            in, your picture and other information you may share with us.
          </li>
          <li>
            Information regarding your use of our online Services. This may
            include data regarding the pages you visit and features you use.
          </li>
          <li>
            Your purchases online. The data we collect if you purchase a product
            online, such as the amount and date of your purchase, the contest
            you purchase or Service you use (for instance the War Mode), the
            website or app through which you make your purchase, payment method,
            payment status, discount and invoice details.
          </li>
          <li>
            Your leisure activities, interests and events. This includes
            information regarding events we organize or sponsor, including your
            interest in and registration to those events (including the date and
            place of the event and your personal targets), as well as your
            reason for all fitness activities.
          </li>
          <li>
            Your fitness related data. The fitness data that you share on or
            generate through the use of our Services or that you import from
            other apps or services from our partners or third parties, including
            your workout experience and abilities (distance, pace, cadence,
            heart rate, calories burned, flux, streaks, performance, duration of
            training/workouts, pre-set goals, achieving those pre-set goals on
            triStats and much more).
          </li>
          <li>
            Your personal contents, profile and activities that you share on the
            platform with your selected population/public, geo-location of each
            post and engagement on the application
          </li>
          <li>
            Your details regarding Battles/Wars. The data we collect if you
            participate in such contests.
          </li>
          <li>
            Your connected devices. Fettle collects information from devices and
            apps you connect to Fettle. For example, you may connect your
            GoogleFit or Apple Health account to Fettle and information from
            these devices and apps will be passed along to Fettle.
          </li>
          <li>
            Health Information. Fettle may collect or infer health information.
            Certain health information may be inferred from sources such as
            heart rate or other measurements. By signing up and creating an
            account on Fettle, you give your consent to share such data with us.
          </li>
          <li>
            Payment Information. When you make a payment on Fettle, you may
            provide payment information such as your payment card or other
            payment details, which are completely encrypted end-to-end. We use
            Payment Card Industry compliant third-party payment services and we
            do not store any such information.
          </li>
          <li>
            Third-Party Accounts. Fettle allows you to sign up and log in to the
            Services using accounts you create with third-party products and
            services, such as Facebook or Google (collectively, “Third-Party
            Accounts”). If you access the Services with Third-Party Accounts we
            will collect information that you have agreed to make available such
            as your name, email address, profile information and preferences.
            This information is collected by the Third-Party Account provider
            and is provided to Fettle under their privacy policies. You can
            generally control the information that we receive from these sources
            using the privacy controls in your Third-Party Account.
          </li>
          <li>
            Technical Information and Log Files. We collect information from
            your browser, computer, or mobile device, which provide us with
            technical information when you access or use the Services. This
            technical information includes device and network information,
            cookies, log files and analytics information.This is to make your
            Fettle experience worth coming back to.
          </li>
          <li>
            Your reviews. The opinions, experiences, preferences and interests,
            and product or event reviews that you publish on our websites or
            share with us online or through social media.
          </li>
          <li>
            Your communication data. Your requests, any complaints you may have
            and any other data that we receive if we communicate with you via
            e-mail, online or via social media.
          </li>
        </ul>
        {/* below block needs padding */}
        <p className="policy-body">
          To comply with a legal obligation to which Fettle is subject: <br />
          Any information referred to above in the section ‘Personal Data’ may
          be used to maintain appropriate business records, to comply with
          lawful requests by public authorities and to comply with applicable
          laws and regulations or as otherwise required by law. <br />
          We assure you that we keep such data fully protected with industry
          standard Secure Sockets Layer (SSL).
        </p>
        <p className="policy-header">Usage of the information</p>
        <ul>
          <li>To Provide Services:</li>
          <ul>
            <li>Compete in Battles/Wars and secure a rank in leaderboards</li>
            <li>
              Store your activities and aggregate/analyze your performance
            </li>
            <li>Interact with other Fettle members</li>
            <li>Share activities with your elected population</li>
          </ul>
          <li>To Customize Your Experience:</li>
          <ul>
            <li>
              All such data collected is utilised to provide you a customized
              experience. For example, if you achieve your daily triStats for N
              days in row, we will nudge you to set a new target!
            </li>
          </ul>
          <li>To Protect You and Fettle:</li>
          <ul>
            <li>
              We use the information we collect to ensure compliance with the
              Terms & Conditions and Community Standards and promote confidence
              and safety. For example: We remove any illicit content that comes
              to our notice, and suspend/terminate such accounts that share the
              same.
            </li>
          </ul>
          <li>To Improve Our Services:</li>
          <ul>
            <li>
              We analyse the information we collect to improve the Fettle
              experience daily.
            </li>
          </ul>
          <li>Aggregate Information:</li>
          <ul>
            <li>
              We do not sell your personal information. Fettle may aggregate the
              information you and others make available in connection with the
              Services and post it publicly or share it with third parties.
              Examples of the type of information we may aggregate include
              information about Battles, usage, demographics, and performance.
              Fettle may use, license, and share this aggregated anonymous
              information with third parties for research, business or other
              purposes such as to improve the services for you and help the
              world come up with novel solutions to assist you in your fitness
              journey.
            </li>
          </ul>
          <li>To Communicate with You:</li>
          <ul>
            <li>
              We use your contact information to have open lines of
              communication with you for any support or feedback.
            </li>
          </ul>
        </ul>
        <p className="policy-header">Sharing of Information</p>
        <ul>
          <li>Public information:</li>
          <ul>
            <li>
              The information you share while creating your account at Fettle
              such as name, user ID, photos and other profile data are shared
              with the public subject to your privacy controls
            </li>
          </ul>
          <li>Service Providers and Business Partners:</li>
          <ul>
            <li>
              We may share your information with third parties that provide
              services to Fettle such as improving, monitoring and securing the
              Services, processing payments, and so on. These service providers
              only have limited access to the information that they need to
              provide limited functions as relevant to them and have the utmost
              accountability and responsibility to protect and secure such data.
            </li>
          </ul>
          <li>Third Party Integrations:</li>
          <ul>
            <li>
              We permit you to share your information with other third party
              apps, websites or plug-ins such as Facebook, Twitter, Snapchat and
              so on at your discretion that integrate with the Services, as well
              as with third party providers that offer an integrated feature in
              terms of sponsored Battles/Wars. It's your choice to share your
              profile data and activities. Such information which is shared with
              the third party providers is subject to their privacy policy and
              security controls; Fettle is not responsible for the terms &
              conditions of third parties
            </li>
          </ul>
          <li>Social Network Sharing:</li>
          <ul>
            <li>
              As a novel social network for fitness & wellness, we share your
              public information with other members of the community in
              accordance to the privacy control settings that you set
            </li>
          </ul>
          <li>Legal information:</li>
          <ul>
            <li>
              We may secure and share your information with law enforcement
              agencies, public or private litigants and government agencies, if
              we determine that such disclosure is in accordance with the laws
              and regulations or we reasonably confirm the necessity of sharing
              such information.
            </li>
          </ul>
        </ul>
        <p className="policy-header">Information Protection</p>
        <p className="policy-body">
          We secure all your information as per highest industry standards and
          ensure reasonable protocols to be followed while collecting,
          transmitting, utilising or processing such information. The third
          party secure servers we use to store your information complies with
          industry standards of data privacy and control.
        </p>
        <p className="policy-header">Settings Management</p>
        <p className="policy-body">
          Fettle provides detailed privacy controls in your settings section of
          the application in order to give users the control on the information
          they would like to keep private or public. Ensure you go through the
          settings page to set your controls as per your requirements. On
          signing up, we provide every user the same default settings. You can
          change them anytime you want.
        </p>
        <p className="policy-header">Transfers</p>
        <p className="policy-body">
          The Services are operated from India. If you’re located from outside
          India, you acknowledge and understand that your information will be
          transferred, processed and stored securely in India as it is necessary
          to provide said Services.
        </p>
        <p className="policy-header">Information Retention</p>
        <p className="policy-body">
          We retain your information till it is necessary to provide the
          Services to you and others, subject to any legal obligations to
          further retain such information. Data relevant to your account will
          likely be kept until it is no longer required to provide the Services
          or your account is deleted. We make a soft delete of your account such
          that if you log in again in the future the basics of your account
          information is ready to be used and saves you from any hassle of
          inputting again. Information identifiable to you that is no longer
          required and relevant to provide our Services, maybe de-identified or
          aggregated (made anonymous) to provide insights which are commercially
          valuable to Fettle and its business partners.
        </p>
        <p className="policy-header">Changes to the Policy</p>
        <p className="policy-body">
          Fettle reserves the right to change or modify this Privacy Policy at
          any time. Kindly review it periodically. When Fettle makes changes to
          this Privacy Policy, it will be updated in a timely manner.
        </p>
      </div>
    </>
  );
}

// export default PrivacyPolicy;