import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import VisibilitySensor from "react-visibility-sensor";
import { useEffect } from "react";
import PureCounter from "@srexi/purecounterjs";

export const ProgressChart = () => {
  useEffect(() => {
    new PureCounter({
      selector: ".pureCounter",
      duration: 1,
      delay: 50,
      pulse: true,
      once: false,
      repeat: true,
      legacy: true,
      filesizing: false,
      currency: false,
      separator: true,
    });
  }, []);

  return (
    <section className="stats" id="impact">
      <div className="container">
        <div className="sep" />
        <div className="float-left">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="battle mx-auto">
                  <h6>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="1013"
                      className="pureCounter"
                    >
                      0
                    </span>
                  </h6>
                  <p>Battles Created</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-5">
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="cal mx-auto">
                  <h6>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="3.23"
                      data-purecounter-decimals="2"
                      className="pureCounter"
                    >
                      0
                    </span>{" "}
                    mil
                  </h6>
                  <p>KCal Burned</p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="kilo mx-auto">
                  <h6>
                    <span
                      data-purecounter-start="0"
                      data-purecounter-end="32691"
                      className="pureCounter"
                    >
                      0
                    </span>
                  </h6>
                  {/*<h6>7,242</h6>*/}
                  <p>Kilometers Covered</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="stat progress-bar-chart" id="progress">
          <div className="goals">
            <VisibilitySensor>
              {({ isVisible }) => {
                const percentage = isVisible ? 75 : 0;
                return (
                  <CircularProgressbar
                    value={percentage}
                    text={`75%`}
                    styles={buildStyles({
                      textColor: "#7540B0",
                      pathColor: "#ffffff",
                      trailColor: "#141414",
                      backgroundColor: "#141414",
                      textSize: "22px",
                      pathTransitionDuration: 1.5,
                    })}
                  />
                );
              }}
            </VisibilitySensor>
            <div className="text">
              <div data-aos="fade-up" data-aos-duration="1000">
                <p>
                  Fettle employees are <span>actively engaged</span> and meeting
                  their fitness goals
                </p>
              </div>
            </div>
          </div>

          <div className="border" />

          <div className="session">
            <VisibilitySensor>
              {({ isVisible }) => {
                const percentage = isVisible ? 100 : 0;
                return (
                  <CircularProgressbar
                    value={percentage}
                    text={"2.1"}
                    background
                    styles={buildStyles({
                      textColor: "#7540B0",
                      pathColor: "#7540B0",
                      trailColor: "#ffffff",
                      backgroundColor: "#ffffff",
                      textSize: "22px",
                      pathTransitionDuration: 1.5,
                      // strokeLinecap: "butt",
                    })}
                  />
                );
              }}
            </VisibilitySensor>
            <div className="text">
              <div data-aos="fade-up" data-aos-duration="1000">
                <p>
                  Battles are created from <span>every recorded</span> Fettle
                  session
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="sep" id="cases" />
      </div>
    </section>
  );
};
