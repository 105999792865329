import React, { useState } from "react";
import { ROUTES, SOCIAL_DETAILS } from "../../utils/constant";
import FettleWhiteLogo from "../../assets/fettleWhiteLogo.svg";
import AppStore from "../../assets/appStore.png";
import PlayStore from "../../assets/playStore.png";
import { v4 as uuidv4 } from "uuid";
import fetchServer from "../../api/server";
import { useSnackbar } from "react-simple-snackbar";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export const Footer = () => {
  const [email, setEmail] = useState("");
  const [openSnackbar] = useSnackbar({
    position: "bottom-right",
    style: {
      backgroundColor: "midnightblue",
      border: "2px solid lightgreen",
      color: "lightblue",
      fontSize: "20px",
      textAlign: "center",
    },
    closeStyle: {
      color: "lightcoral",
      fontSize: "16px",
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (email.length === 0) {
      openSnackbar("Please fill the required field...");
      return;
    }
    await fetchServer
      .post("/contact", {
        email,
        type: "B2C",
      })
      .then((res) => {
        if (res.data.code === 221) {
          openSnackbar("Sorry Email could not be saved !!");
          return;
        }
        if (res.data.code === 220) {
          openSnackbar("You are already in our registered list !!");
          return;
        }
        openSnackbar(
          "Thanks for reaching us out, we will get back to you shortly !!"
        );
        setEmail("");
      })
      .catch((err) => {
        openSnackbar("Something went wrong, Please try again later !!");
      });
  };

  return (
    <footer>
      <div className="container">
        <div className="newsletter">
          <div className="row">
            <div className="col-lg-7 col-md-12">
              <h3>Take the leap</h3>
              <p>We’ll setup a free trial for your team. No restrictions.</p>
            </div>
            <div className="col-lg-5 col-md-12">
              <form className="row align-items-center">
                <div className="col-md-8">
                  <label htmlFor="Email" className="visually-hidden">
                    Email
                  </label>
                  <input
                    type="Email"
                    className="form-control"
                    id="Email"
                    placeholder="Your work email"
                    value={email}
                    onChange={({ target: { value } = {} }) => setEmail(value)}
                  />
                </div>
                <div className="col-md-4">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="row align-items-end">
            <div className="col-md-4">
              <button
                key={uuidv4()}
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.fettle.app",
                    "_blank"
                  )
                }
                className="store"
              >
                <img src={PlayStore} alt="Play Store" className="img-fluid" />
              </button>
              <button
                key={uuidv4()}
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/fettle-plus/id1591969946",
                    "_blank"
                  )
                }
                className="store"
              >
                <img src={AppStore} alt="Apple Store" className="img-fluid" />
              </button>
              <p>© 2021 FETL Tech Pvt. Ltd. All rights reserved.</p>
            </div>
            <div className="col-md-4 text-center resources">
              <h4>Resources</h4>
              <br />
              {ROUTES.map((route) => (
                <React.Fragment key={uuidv4()}>
                  {route.link.includes("#") ? (
                    <HashLink className="nav-link" smooth to={route.link}>
                      {route.label}
                    </HashLink>
                  ) : (
                    <Link className="nav-link" href={route.link}>
                      {route.label}
                    </Link>
                  )}
                </React.Fragment>
              ))}
              {/* <a key={uuidv4()} href="https://www.fettle.plus/Help" target="_blank" className="nav-link" rel="noreferrer">Help Center</a> */}
              <br />
              <img src={FettleWhiteLogo} alt="Logo" className="img-fluid" />
            </div>
            <div className="col-md-4">
              <ul className="social">
                {SOCIAL_DETAILS.map((item) => (
                  <li key={uuidv4()}>
                    <button onClick={() => window.open(item.link, "_blank")}>
                      {item.logo}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
