export const ProductCard = ({ data }) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="feature">
        <div className="feature-inner">
          <div className="feature-front">
            <div data-aos="fade-up" data-aos-duration="2000" className="aos-init aos-animate">
              <div className="box">
                {data.icon}
              </div>
              <h4>{data.label}</h4>
              {data.description}
            </div>
          </div>
          <div className="feature-back">
            <img src={data.cardImage} alt="Card icon" />
            <p>{data.cardDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
