import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../../styles/blog.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { LeftArrowIcon } from "../../assets";

export const Blog = () => {
  const history = useHistory();

  const containerRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const el = containerRef.current;
    var tl = gsap.timeline({});

    let mm = gsap.matchMedia(el);

    mm.add(
      {
        isMobile: "(max-width: 500px)",
        isDesktop: "(min-width: 501px)",
        isWidth1920: "(min-width: 1920px)",
        isWidth1366: "(min-width: 1366px)",
        isWidth1280: "(min-width: 1280px)",
        isWidth800: "(min-width: 800px)",
        isWidth414: "(max-width: 415px)",
      },
      (context) => {
        let { isWidth1920, isWidth1366, isWidth1280, isWidth800, isWidth414 } =
          context.conditions;

        tl.from(el.querySelector(".blog-hero-text"), {
          opacity: 0,
          y: -20,
          duration: 1.5,
          delay: 0.3,
        });

        tl.fromTo(
          el.querySelector(".blog-hero-text"),
          {
            opacity: 1,
            y: 0,
          },
          {
            opacity: 0,
            y: -300,
            scrollTrigger: {
              trigger: ".blog-hero-text",
              pin: ".blog-banner",
              // start: "-80 center+=60",
              start: isWidth1920
                ? "top bottom-=243"
                : isWidth1366
                ? "top bottom-=230"
                : isWidth1280
                ? "top bottom-=237"
                : isWidth800
                ? "top bottom-=194"
                : isWidth414
                ? "top bottom-=239"
                : null,
              end: "top 10%",
              scrub: true,
              markers: true,
            },
          }
        );

        tl.fromTo(
          el.querySelector(".blog-head"),
          {
            opacity: 0,
          },
          {
            opacity: 1,
            scrollTrigger: {
              trigger: ".blog-head",
              // pin: ".banner",
              start: isWidth1920 ? "top center" : "top center+=220",
              end: "top 10%",
              scrub: true,
              // markers: true,
            },
          }
        );
      }
    );
  }, []);

  return (
    <div ref={containerRef} className="wrapper blog">
      {/* <div className="wrapper blog"> */}
      <button id="backBtn" onClick={() => history.push("/")}>
        <LeftArrowIcon />
      </button>
      <section className="blog-banner component-container">
        <div className="blog-hero-text">
          <h1>Work and Play in the New Decade</h1>
          <p>July 20, 2022 | Article</p>
        </div>
      </section>
      <section className="blog-head component-container">
        <div className="container">
          <div className="text">
            <h4>
              As the joust between what was the status quo and the new normal
              develops,
              <br />
              we would be remiss not to grasp the speed at which the variables
              of labor and leisure,
              <br />
              pervasive from top to bottom, are moving.
            </h4>
          </div>
        </div>
      </section>
      <section className="blog-content">
        <div className="container">
          <div className="author">
            By&nbsp;
            {/* <a href="https://www.linkedin.com/in/shuvam-burnwal/"> */}
            Author Surname
            {/* </a> */}
          </div>
          <p>
            <strong>
              <span className="big">O</span>n July 7, 2020
            </strong>{" "}
            Praesent sapien massa, convallis a pellentesque nec, egestas non
            nisi. Cras ultricies ligula sed magna dictum porta. Vestibulum ante
            ipsum primis in faucibus orci luctus et ultrices posuere cubilia
            Curae; Donec velit neque, auctor sit amet aliquam vel, ullamcorper
            sit amet ligula. Mauris blandit aliquet elit, eget tincidunt nibh
            pulvinar a. Vestibulum ac diam sit amet quam vehicula elementum sed
            sit amet dui. Curabitur non nulla sit amet nisl tempus convallis
            quis ac lectus. Curabitur arcu erat, accumsan id imperdiet et,
            porttitor at sem. <u>Proin eget tortor risus. Vestibulum</u> ac diam
            sit amet quam vehicula elementum sed sit amet dui. Proin eget tortor
            risus.
            <br />
            <br />
            Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet
            ligula. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            Vestibulum ac diam sit amet quam vehicula elementum sed sit amet
            dui. Curabitur non nulla sit amet nisl tempus convallis quis ac
            lectus. Curabitur arcu erat, accumsan id imperdiet et, porttitor at
            sem. Proin eget tortor risus. Vestibulum ac diam sit amet quam
            vehicula elementum sed sit amet dui. Proin eget tortor risus.
          </p>
          <p>
            Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            Vestibulum ac diam sit amet quam vehicula elementum sed sit amet
            dui. Curabitur non nulla sit amet nisl tempus convallis quis ac
            lectus. Curabitur arcu erat, accumsan id imperdiet et, porttitor at
            sem. Proin eget tortor risus. Vestibulum ac diam sit amet quam
            vehicula elementum sed sit amet dui. Proin eget tortor risus.
            <br />
            <br />
            Donec velit neque, auctor sit amet aliquam vel, ullamcorper sit amet
            ligula. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a.
            Vestibulum ac diam sit amet quam vehicula elementum sed sit amet
            dui. Curabitur non nulla sit amet nisl tempus convallis quis ac
            lectus. Curabitur arcu erat, accumsan id imperdiet et, porttitor at
            sem. Proin eget tortor risus. Vestibulum ac diam sit amet quam
            vehicula elementum sed sit amet dui. Proin eget tortor risus.
          </p>
        </div>
      </section>
    </div>
  );
};
