import React from "react";
import { hydrate, render } from "react-dom";
import App from "./App";
// import SnackbarProvider from 'react-simple-snackbar'

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  // hydrate(<SnackbarProvider><App /></SnackbarProvider>, rootElement);
  hydrate(<App />, rootElement);
} else {
  // render(<SnackbarProvider><App /></SnackbarProvider>, rootElement);
  render(<App />, rootElement);
}
