import { PRODUCTS } from "../../utils/constant";
import { ProductCard } from "../../components/Cards/productCard";
import { v4 as uuidv4 } from "uuid";

export const Products = () => {
  return (
    <section className="features text-center" id="features">
      <div className="container">
        <h2>{PRODUCTS.label}</h2>
        {PRODUCTS.description}
        <div className="row">
          {PRODUCTS.data.map((item) => (
            <ProductCard key={uuidv4()} data={item} />
          ))}
        </div>
      </div>
    </section>
  );
};
