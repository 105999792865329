export const CommentsCard = ({ data }) => {
    return (
        <div className="boxes" data-aos="fade-up" data-aos-duration="2000">
            <div className="box">
                <p>{data.message}</p>
                <div className="client-names">
                        <span>
                              {data.name}
                            <br />
                              <span>{data.code}</span>
                        </span>
                    <img src={data.imgName} alt="noLogo" width="15%" />
                </div>
            </div>
        </div>
    );
};
