import React from "react";
import "./Error404.css";

function Error404() {
  return (
    <>
      <div className="maindiv">
        <div className="noise" />
        <div className="overlay" />
        <div className="terminal">
          <h1>
            Error <span className="errorcode">404</span>
          </h1>
          <p className="output">The page you are looking for does not exist.</p>
          <p className="output">
            Please try to{" "}
            {/* <script>
              document.write('<a class="bracketlink" href="' + document.referrer + '">go back</a>');
            </script> */}
            <a className="bracketlink" href="/">
              go back
            </a>{" "}
            or{" "}
            <a className="bracketlink" href="/">
              return to the homepage
            </a>
            .
          </p>
          <p className="output">Good luck.</p>
        </div>
      </div>
    </>
  );
}

export default Error404;
