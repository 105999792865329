import { Link } from "react-router-dom";
import FettleBlackLogo from "../../assets/fettleBlackLogo.svg";
import { ROUTES } from "../../utils/constant";
import { v4 as uuidv4 } from "uuid";
import { HashLink } from "react-router-hash-link";

export const Header = ({ scroll }) => {
  return (
    <header className={"header " + (scroll ? "scroll" : "")}>
      <nav className="navbar navbar-expand-lg desk-nav">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={FettleBlackLogo} alt="Logo" className="img-fluid" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              {ROUTES.map((route, index) => (
                <li className="nav-item" key={uuidv4()}>
                  {route.link.includes('#') ?
                    <HashLink className="nav-link" smooth to={route.link}>
                      {route.label}
                    </HashLink> :
                    <Link className="nav-link" to={route.link}>
                      {route.label}
                    </Link>
                  }
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
      <div className="mobile-header">
        <nav className="navbar scroll">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <img src={FettleBlackLogo} alt="Logo" className="img-fluid" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar">
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              aria-hidden="true">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Offcanvas</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                  {ROUTES.map((route, index) => (
                    <li className="nav-item" key={uuidv4()} data-bs-dismiss="offcanvas" aria-label="Close">
                      <HashLink className="nav-link" smooth to={route.link}>
                        {route.label}
                      </HashLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
