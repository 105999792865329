import { v4 as uuidv4 } from "uuid";
import { CLIENT_COMMENTS } from "../../utils/constant";
import { CommentsCard } from "../../components/Cards/CommentsCard";

export const Comments = () => {
  return (
    <section className="testimonial text-center">
      <div className="container">
        <h2>{CLIENT_COMMENTS.label}</h2>
        {CLIENT_COMMENTS.description}
        <div className="d-flex">
          {CLIENT_COMMENTS.comments.map((item) => (
            <CommentsCard data={item} key={uuidv4()} />
          ))}
        </div>
      </div>
    </section>
  );
};
