import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Joy from "../../assets/clients-carousel/Joy.webp";
import Pinnacle from "../../assets/clients-carousel/Pinnacle.webp";
import Plushpro from "../../assets/clients-carousel/Plushpro.webp";
import Suraksha from "../../assets/clients-carousel/Suraksha.webp";
import Cars24 from "../../assets/clients-carousel/Cars24.webp";
import Wtw from "../../assets/clients-carousel/Wtw.webp";
import Niro from "../../assets/clients-carousel/Niro.webp";
import Chubb from "../../assets/clients-carousel/Chubb.webp";
import Godrejcap from "../../assets/clients-carousel/Godrejcap.webp";
import Decisionfoundry from "../../assets/clients-carousel/Decisionfoundry.webp";
import Brigade from "../../assets/clients-carousel/Brigade.webp";

export const ClientLogos = () => {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 4,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };

  return (
    <section className="clients text-center">
      <div className="container">
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay
          autoPlaySpeed={1000}
          centerMode={false}
          className=""
          containerClass="container-with-dots"
          customTransition="all 1s linear"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite
          rewind={false}
          rewindWithAnimation={false}
          shouldResetAutoplay
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          rtl={false}
          showDots={false}
          sliderClass=""
          slidesToSlide={1}
          swipeable
          transitionDuration={10}
        >
          <div>
            <img src={Wtw} alt="Wtw" />
          </div>
          <div>
            <img src={Godrejcap} alt="Godrejcap" />
          </div>
          <div>
            <img src={Brigade} alt="Brigade" />
          </div>
          <div>
            <img src={Decisionfoundry} alt="Decisionfoundry" />
          </div>
          <div>
            <img src={Chubb} alt="Chubb" />
          </div>
          <div>
            <img src={Niro} alt="Niro" />
          </div>
          <div>
            <img src={Joy} alt="Joy" />
          </div>
          <div>
            <img src={Pinnacle} alt="Pinnacle" />
          </div>
          <div>
            <img src={Cars24} alt="Cars24" />
          </div>
          <div>
            <img src={Suraksha} alt="Suraksha" />
          </div>
          <div>
            <img src={Plushpro} alt="Plushpro" />
          </div>
        </Carousel>
      </div>
    </section>
  );
};
