import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SnackbarProvider from 'react-simple-snackbar'
import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import "./styles/common.css";
import "./styles/tokens.css";

import ScrollToTop from "./components/ScrollToTop";
import Error404 from "./components/Error404/Error404";
import { BasePage } from "./containers/BasePage.js";
import { Blog } from "./containers/Blog/Blog";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <SnackbarProvider>
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/blog" exact component={Blog} />
          <Route path="/" exact component={BasePage} />
          <Route path="/privacy" exact component={PrivacyPolicy} />
          <Route
            path="/terms"
            component={() => {
              window.location.replace(
                "https://fettleplus.notion.site/Terms-Conditions-6208436baf0543e2b7cce8e0f9fe2c74"
              );
              return null;
            }}
          />
          <Route
            path="/help-center"
            component={() => {
              window.location.replace(
                "https://fettleplus.notion.site/fettleplus/Community-Onboarding-97fe85d91af643288968f7f05072b970"
              );
              return null;
            }}
          />
          <Route path="*" exact component={Error404} />
        </Switch>
      </ScrollToTop>
    </Router>
    </SnackbarProvider>
  );
}

export default App;
