import React, { useState } from "react";
import { FAQ_DATA } from "../../utils/constant";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50vh;
  h2 {
    padding: 5vh 10vw;
    text-align: center;
  }
  /* @media (max-width:767px) {
    width: 90vw;
  } */
`;

const Container = styled.div`
  /* position: absolute; */
  width: 70vw;
  /* top: 30%; */
  /* box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3); */

  @media (max-width: 767px) {
    width: 90vw;
  }
`;

const Wrap = styled.div`
  background: #272727;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  h1 {
    padding: 2rem;
    font-size: var(--p-font);
    font-weight: 100;
  }
  span {
    margin: 2vh 5vw;
    background-color: #272727;
    /* margin-right: 1.5rem; */
  }

  @media (max-width: 767px) {
    h1 {
      font-size: var(--p-font-mobile);
      margin: 3vh 3vw;
      padding: 0 0;
    }
    span {
      margin-left: 2%;
    }
  }
`;

const Dropdown = styled.div`
  background: #1c1c1c;
  color: #7540b0;
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 2vh 2vw;
  border-bottom: 1px solid #7540b0;
  border-top: 1px solid #7540b0;
  p {
    font-size: var(--p-font);
    font-weight: 100;
  }

  @media (max-width: 767px) {
    p {
      font-size: 0.9em;
      text-align: center;
    }
  }
`;

export const FAQs = () => {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    // <section className="cases text-center">
    <div className="container">
      {/* {FAQ_DATA.description} */}
      <IconContext.Provider value={{ color: "#7540b0", size: "3vh" }}>
        <AccordionSection>
          <h2>{FAQ_DATA.label}</h2>
          <Container>
            {FAQ_DATA.data.map((item, index) => {
              return (
                <>
                  <Wrap onClick={() => toggle(index)} key={index}>
                    <h1>{item.question}</h1>
                    <span>
                      {clicked === index ? <AiOutlineUp /> : <AiOutlineDown />}
                    </span>
                  </Wrap>
                  {clicked === index ? (
                    <Dropdown>
                      <p>{item.answer}</p>
                    </Dropdown>
                  ) : null}
                </>
              );
            })}
          </Container>
        </AccordionSection>
      </IconContext.Provider>
      {/* <div className="sep" /> */}
    </div>
    // </section>
  );
};
