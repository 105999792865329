import {
  CommentsIcon,
  EarMachineIcon,
  FacebookIcon,
  FileLogo,
  GlobeIcon,
  InstagramLogo,
  LikeIcon,
  LinkedInLogo,
  StarIcon,
  TwitterLogo,
} from "../assets";
import Cars24_logo from "../assets/cars24_logo.webp";
import IPC_logo from "../assets/ipc_logo.webp";
import JOY_logo from "../assets/joy_logo.webp";
import CardImage1 from "../assets/cardImg1.webp";
import CardImage2 from "../assets/cardImg2.webp";
import CardImage3 from "../assets/cardImg3.webp";
import CardImage4 from "../assets/cardImg4.webp";
import CardImage5 from "../assets/cardImg5.webp";
import CardImage6 from "../assets/cardImg6.webp";
import UseCase1 from "../assets/UseCase1.webp";
import UseCase2 from "../assets/UseCase2.webp";
import UseCase3 from "../assets/UseCase3.webp";

export const LANDING_PAGE_DESCRIPTION = (
  <p>
    Fettle is gamified employee engagement that connects your teams <br />
    and works without any effort
  </p>
);

export const PRODUCTS = {
  label: "Strategic Team Engagement",
  description: (
    <p>
      Employee Engagement evolved rapidly last year.
      <br /> Fettle blends smart tech with in-vogue strategies, to elevate team
      engagement and company culture.
    </p>
  ),
  data: [
    {
      label: "Limitless Battles",
      icon: <StarIcon />,
      description: (
        <p>
          Compete across activities:
          <br />
          cycling Sunday to a month's marathon
        </p>
      ),
      cardImage: CardImage1,
      cardDescription:
        "Ensure every member feels included and excited - in their own way",
    },
    {
      label: "Exclusive Spaces",
      icon: <FileLogo />,
      description: (
        <p>
          Get a private, personalized
          <br />
          platform for your teams
        </p>
      ),
      cardImage: CardImage2,
      cardDescription:
        "Complete data security and access control for all teams",
    },
    {
      label: "Rewards and Recognition",
      icon: <GlobeIcon />,
      description: (
        <p>
          15% of your subscription fee
          <br />
          is rewarded to employees
        </p>
      ),
      cardImage: CardImage3,
      cardDescription:
        "Sometimes you need a literal prize for employee engagement",
    },
    {
      label: "Engagement Architects",
      icon: <EarMachineIcon />,
      description: (
        <p>
          Our engagement experts
          <br />
          will enhance all your ideas & events
        </p>
      ),
      cardImage: CardImage4,
      cardDescription:
        "Let us take you from planning to execution, while you participate",
    },
    {
      label: "Mental Health",
      icon: <LikeIcon />,
      description: (
        <p>
          Nourish and calm your mind
          <br />
          when you choose to rest
        </p>
      ),
      cardImage: CardImage5,
      cardDescription:
        "Access an entire suite of meditation, deep sleep, focus, and anti-anxiety services.",
    },
    {
      label: "Content Branding",
      icon: <CommentsIcon />,
      description: (
        <p>
          All media design is our burden:
          <br />
          ensuring participation and engagement
        </p>
      ),
      cardImage: CardImage6,
      cardDescription:
        "Make all your engagement events look like the one to envy this year",
    },
  ],
};

export const SOLUTIONS = {
  label: "Virtual Workplace Engagement",
  description: (
    <p>
      Fettle is employee engagement overhauled for The New Decade.
      <br /> The successes we've witnessed span several domains of wellness.
    </p>
  ),
  data: [
    {
      label: "Better Inclusivity. One Plan.",
      title: "Tailored Tournaments",
      description:
        "Take your teams on a multi-level wellness challenge - while caring for every employee's preferences and goals.",
      points: [
        "Segment your employees: by fitness style, leader, or department.",
        "Analytics on winning employees, effort scores, or a custom metric.",
        "Recognize employees from each segment for maximum participation.",
      ],
      imgSrc: UseCase1,
    },
    {
      label: "Thoughtfulness Incentivized.",
      title: "Connected CSR",
      description:
        "Combine the drivers of charity and wellness, to create a purposeful sense of employee engagement.",
      points: [
        "Link a teams' wellness goals to your company's CSR projects.",
        "Get live project progress and impact across employees and leaders.",
        "Employees choose the engagement channels they contribute to.",
      ],
      imgSrc: UseCase2,
    },
    {
      label: "Indulge Every Interest.",
      title: "Holistic Engagements",
      description:
        "Capitalize on the Fettle Calendar to create employee engagement across the year, customized to your company culture.",
      points: [
        "Use gamification to inject active participation in boring events.",
        "Fettle doubles as the digital interface to all celebrations and events.",
        "Heart Health Week, Thanksgiving, or Mother's Day - it all fits.",
      ],
      imgSrc: UseCase3,
    },
  ],
};

export const CLIENT_COMMENTS = {
  label: "Hear Our Ambassadors",
  description: (
    <p>
      Culture Directors. C-Suite Executives. Wellness Experts.
      <br />
      Fettle excels at catering to stakeholders at every step of employee
      engagement.
    </p>
  ),
  comments: [
    {
      message:
        "With Fettle, we ran various workout challenges and shared photos from sweat sessions and even follow the colleague's fitness activities. It brings community along with giving challenges and a platform to battle against your colleagues.”",
      name: "Prerna Narula",
      code: "Manager, People & Culture",
      imgName: Cars24_logo,
    },
    {
      message:
        "Fettle is primed to change how firms make their teams a fun and active space to participate in. They even make your scoresheets look like it's the World Cup!",
      name: "Sunil Agarwal",
      code: "Group Chairman, RSH Global",
      imgName: JOY_logo,
    },
    {
      message:
        "As someone at the intersection of inclusion and fitness, I believe Fettle is a courageous take on community incentives and engagement. This solution is brilliantly simple, and yet so effective. Also, it looks so good - every screen oozes class.",
      name: "Saloni Gupta",
      code: "Coordinator, International Paralympics",
      imgName: IPC_logo,
    },
  ],
};

export const FAQ_DATA = {
  label: "Quick Q&As",
  description: (
    <p>
      Culture Directors. C-Suite Executives. Wellness Experts.
      <br />
      Fettle excels at catering to stakeholders at every step of employee
      engagement.
    </p>
  ),
  data: [
    {
      question:
        "What employee engagement do I get with Fettle?",
      answer:
        "End-to-end gamification of team wellness; across departments, activities, and countries, exclusively. We drive everything, participation to recognition, for events on your calendar.",
    },
    {
      question: "Why should I try Fettle's employee engagement over others?",
      answer: "Nobody gives you peer-to-peer gamification of employee engagement. We make it easier than setting a meeting. All you worry about is getting on your feet.",
    },
    {
      question: "How does the pricing work?",
      answer: "$1 per user, per month, on average. Prices decrease for larger packages.",
    },
  ],
};

export const ROUTES = [
  { label: "Impact", link: "#impact" },
  { label: "Use Cases", link: "#cases" },
  // { label: "Contact Us", link: "mailto:team@fettle.plus?subject=Fettle%20|%20Contact%20Us%20|%20Website&body=Hi,%20I'd%20like%20to%20know%20more%20about%20employee%20engagement%20with%20Fettle!%0D%0AMy%20work%20email%20is%20________________" },
  // { label: "Help Center", link: "https://fettleplus.notion.site/fettleplus/Community-Onboarding-97fe85d91af643288968f7f05072b970" },
  // { label: "Privacy Policy", link: "/privacy" },
  // {
  //   label: "Blog",
  //   link: "/blog"
  // }
];

export const SOCIAL_DETAILS = [
  {
    logo: <LinkedInLogo />,
    link: "https://www.linkedin.com/company/fettleplus/",
  },
  { logo: <InstagramLogo />, link: "https://www.instagram.com/fettle.plus" },
  { logo: <TwitterLogo />, link: "https://twitter.com/fettle_plus" },
  { logo: <FacebookIcon />, link: "https://www.facebook.com/fettle.plus.21/" },
];

export const RESOURCE_DETAILS = [
  ...ROUTES,
  // { label: "Help Center", link: "https://www.fettle.plus/Help" }
];
